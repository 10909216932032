import { useDispatch, useSelector } from 'react-redux';
import { closeImage } from '../slices/appSlice';
import { useSize } from '../app/customHooks';

export default function ImageViewer() {
  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();
  useSize(appState.showImage);

  const handleClose = () => {
    dispatch(closeImage());
  }
  return (
    <div 
      className="popup fixed w-full h-full left-0 top-0 bg-black bg-opacity-70 flex justify-center items-center z-10 overflow-x-hidden"
    >
      <div 
        className="relative bg-black box-border z-20 flex flex-col flex-wrap items-center text-center"
      >
        <div 
          className="absolute text-slate-700 text-5xl top-0 right-3 z-20 cursor-pointer hover:text-slate-500"
          onClick={handleClose}
          onKeyUp={handleClose}
        >
          &times;
        </div>
        <img 
          className={"max-w-[80vw] max-h-[80vh]"}
          src={appState.showImage}
          alt="img" 
        />
      </div>
    </div>
  );
}