import { useDispatch } from 'react-redux';
import { listView, tileView } from '../slices/storeSlice';

export default function ViewBtn({ view }) {
  const dispatch = useDispatch();
  const handlers = {
    'tile': () => dispatch(tileView()),
    'list': () => dispatch(listView()),
  };

  const handleClick = () => {
    handlers[view]();
  }

  return (
    <div
      className={(view === 'tile' ? "w-[2.5vh]" : "w-[3vh]") +" mx-2 cursor-pointer"}
      onClick={handleClick}
    >
      <img src={`/img/view/${view}_view.png`} alt="view" />
    </div>
  );
}
