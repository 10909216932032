import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import appReducer from '../slices/appSlice';
import storeReducer, { addRemoveToFavorite } from '../slices/storeSlice';
import localforage from 'localforage';

const listenerMiddleware = createListenerMiddleware();
listenerMiddleware.startListening({
  actionCreator: addRemoveToFavorite,
  effect: () => {
    localforage.setItem('favorite', store.getState().store.favorite);
  },
});

export const store = configureStore({
  reducer: {
    app: appReducer,
    store: storeReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(listenerMiddleware.middleware)
});


