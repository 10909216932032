import { Link } from 'react-router-dom';

export default function MainFooter() {
  return (
    <footer
      className="w-full h-[6vh] bg-[#1a1a1a] text-gray-400 text-xs flex items-center justify-around overflow-x-hidden"
    >
        <div>
          2023 © 😁 Юнг Алексей 
        </div>
        <Link
          to={"/siteinfo"}
        >
          <div
            className="underline  hover:text-gray-300"
          >
            Техническая информация
          </div>
        </Link>
    </footer>
  );
}
