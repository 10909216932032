// import { data } from '../app/appData';
import { Outlet, Link } from 'react-router-dom';
import Contacts from '../components/Contacts';
import { useDispatch, useSelector } from 'react-redux';
import ImageViewer from '../components/ImageViewer';
import MainHeader from '../components/MainHeader';
import MainFooter from '../components/MainFooter';

export default function Root() {
  const state = useSelector((state) => state.app);
  const dispatch = useDispatch();

  return (
    <>
      <MainHeader />
      <>
        {state.showContacts ? <Contacts /> : <></>} {/*окно с контактами*/}
        {state.showImage ? <ImageViewer /> : <></>} {/*увеличение изображений*/}
      </>
      <main
        className="overflow-x-hidden"
      >
        <Outlet />
      </main>
      <MainFooter />
    </>
  );
}
