import { useDispatch, useSelector } from 'react-redux';
import { showImage } from '../slices/appSlice';
import { addRemoveToFavorite } from '../slices/storeSlice';
import { Link } from 'react-router-dom';

export default function ItemTileView({ data }) {
  const storeState = useSelector((state) => state.store);
  const dispatch = useDispatch();

  const handleImageClick = () => {
    dispatch(showImage(data.image));
  };

  const handleFavoriteClick = () => {
    dispatch(addRemoveToFavorite(data.id));
  };
  
  return (
    <div
      className="w-[46%] md:w-[30%] h-[25vh] p-2 my-4 flex flex-col flex-wrap justify-between items-center rounded-xl bg-white shadow-[0_0_8px_0_rgba(34,60,80,0.3)]"
    >
      <div
        className="w-2/3 max-h-[12vh] flex justify-center cursor-pointer"
        onClick={handleImageClick}
      >
        <img 
          src={data.image}
          alt="item"
          className="max-w-[90%] max-h-full bg-slate-500"
        />
      </div>
      <Link
        to={`/fakestore/item_card/${data.id}`}
        className="w-full h-[7vh] flex items-center"
      >
        <div
          className="w-full cursor-pointer text-center"
        >
          <p
            className="text-xs md:text-sm"
          >
            {data.title.length > 30 ? data.title.slice(0, 30) + ' ...' : data.title}
          </p>
        </div>
      </Link>
      <div
        className="w-full h-[3vh] flex justify-between"
      >
        <div
          className="w-[30] font-bold text-sm md:text-base"
        >
          <p>
            {(data.price * 100).toString() + ' ₽'}
          </p>
        </div>
        <div
          className="w-[3vh] h-full cursor-pointer"
          onClick={handleFavoriteClick}
        >
          <img
            src={storeState.favorite.includes(data.id) ? "./img/add_to_favorite.png" : "./img/favorite.png"}
            alt=""
            className="h-full"
          />
        </div>
        <div
          className="w-[41%] h-full "
        >
          <button
            className="w-full h-full text-xs border-2 border-gray-400 rounded-md hover:border-gray-500"
          >
            В корзину
          </button>
        </div>
      </div>
    </div>
  );
}
