import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setOnHeight } from '../slices/appSlice';
import localforage from 'localforage';
import { loadFavorite } from '../slices/storeSlice';

export function useSize(path) {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      let img = new Image();
      try {
        const file = await fetch(path).then(r=>r.blob());
        img.src = URL.createObjectURL(file);
      } catch(err) {
        console.log(err);
      }
      await img.decode();
      const width = img.width;
      const height = img.height;

      if (height > width) {
        dispatch(setOnHeight(true));
      } else {
        dispatch(setOnHeight(false));
      }
    })();
  },[]);
}

export function useFavorite() {
  const dispatch = useDispatch();

  useEffect(() => {
    let favorite;
    
    (async () => {
      favorite = await localforage.getItem('favorite');

      if (!favorite) {
        favorite = [];
      }
      dispatch(loadFavorite(favorite));
    })();
  },[]);
}
