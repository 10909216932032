import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { store } from './app/store';
import { Provider } from 'react-redux';
import Root from './routers/Root';
import ErrorPage from './routers/ErrorPage';
import FakeStore from './routers/FakeStore';
import Intro from './routers/Intro';
import AboutMe from './routers/AboutMe';
import Resume from './routers/Resume';
import Cart from './routers/Cart';
import Favorite from './routers/Favorite';
import StoreDefault from './routers/StoreDefault';
import SearchResult from './routers/SearchResult';
import SiteInfo from './routers/SiteInfo';
import { action as fakeStoreAction } from './components/StoreHeader'
import ItemCard, {loader as itemCardLoader } from './routers/ItemCard';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <Intro />,
          },
          {
            path: 'aboutme',
            element: <AboutMe />,
          },
          {
            path: 'resume',
            element: <Resume />,
          },
          {
            path: 'siteinfo',
            element: <SiteInfo />,
          },
          {
            path: 'fakestore',
            element: <FakeStore />,
            action: fakeStoreAction,
            children: [
              {
                index: true,
                element: <StoreDefault />,
              },
              {
                path: 'cart',
                element: <Cart />,
              },
              {
                path: 'favorite',
                element: <Favorite />
              },
              {
                path: 'searchresult',
                element: <SearchResult />
              },
              {
                path: 'item_card/:id',
                element: <ItemCard />,
                loader: itemCardLoader,
              }
            ],
          },
        ],
      },
    ],
  },
]);



ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store} >
    <RouterProvider router={router} />
  </Provider>
);
