import { useSelector } from 'react-redux';
import ViewBtn from '../components/ViewBtn';
import ItemListView from '../components/ItemListView';
import ItemTileView from '../components/ItemTileView';
import { useFavorite } from '../app/customHooks';

export default function StoreDefault() {
  const storeState = useSelector((state) => state.store);
  useFavorite();

  return (
    <div
      className="w-full bg-slate-200 z-0"
    >
      <div
        className="w-full mx-auto xl:w-[80vw] flex justify-between"
      >
        <aside
          className="hidden lg:w-1/4 lg:flex h-[80vh] mt-3 mx-4 bg-white shadow-[0_0_4px_0_rgba(34,60,80,0.2)] rounded-xl flex-wrap justify-around"
        >
          <section>
            <div>Тут будут фильтры</div>
          </section>
        </aside>
        <div
          className="mx-4 lg:w-[70%]"
        >
          <section
            className="h-[5vh] mt-4 mb-2 rounded-md bg-white flex items-center justify-end"
          >
            <ViewBtn view={'list'}/>
            <ViewBtn view={'tile'}/>
          </section>
          <section
            className="w-ful flex flex-wrap justify-between"
          >
            {storeState.view === 'list' ? (
              storeState.storeData.map((el) => {
                return (
                  <ItemListView
                    key={el.id}
                    data={el}
                  />
                );
              })
            ) : (
              storeState.storeData.map((el) => {
                return (
                  <ItemTileView
                    key={el.id}
                    data={el}
                  />
                );
              })
            )}
          </section>
        </div>
      </div>
    </div>
  );
}
