import { NavLink } from 'react-router-dom';
import { data } from '../app/appData';

export default function SubHeader() {
  
  return (
    <div>
      <div
        className="w-screen bg-cover bg-blend-multiply bg-[#8b8b8a] bg-[url('./img/klava3.png')]"
      >
        <div
          className='w-full  py-12'>
            <h1
              className="text-5xl font-['Areal'] font-bold leading-[75px] text-white text-center"
            >
              Всем привет<br />и добро пожаловать!
            </h1>
        </div>
      </div>
      <div
        className='w-full flex justify-center flex-wrap bg-gray-300'
      >
        <div
          className='w-full p-4 sm:w-[65vw] xl:w-[55vw] bg-gray-400'
        >
          <div
            className='flex justify-around'
          >
            {Object.keys(data.menus.submenu).map((item, index)=> (
              <NavLink
                to={item}
                key={index}
                className="text-sm text-gray-800 cursor-pointer hover:text-gray-100"
                style={({ isActive}) => {
                  return {
                    textDecoration: isActive ? "underline" : "",
                  };
                }}
              >
                {data.menus.submenu[item]}
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
