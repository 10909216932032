import { data } from '../app/appData';
import SubHeader from '../components/SubHeader';
import { useDispatch } from 'react-redux';
import { showImage } from '../slices/appSlice';

export default function Resume() {
  const dispatch = useDispatch();

  const handleImageClick = (src) => {
    dispatch(showImage(src));
  }

  return (
    <>
      <SubHeader/>
      <div
        className="w-full flex justify-center flex-wrap bg-gray-300"
      >
        <div
          className="w-full p-4 sm:w-[65vw] xl:w-[55vw] bg-gray-400"
        >
            {data.text.resume.items.map((part, index) => (
            <p
              key={index}
              className="indent-5 pb-2 sm:text-base xl:text-lg"
            >
              {data.text.resume[part]}
            </p>
          ))}
            <div className="flex flex-wrap justify-around">
            {data.certificatesPaths.map((src, index) => (
              <figure
                key={index}
                className="w-[45%] mb-4 "
              >
                  <img 
                    src={src}
                    alt="Sertificate"
                    className="cursor-pointer"
                    onClick={() => handleImageClick(src)}
                  />
              </figure>
              ))}
            </div>
        </div>
      </div>
    </>
  );
}
