import { useDispatch } from 'react-redux';
import { showImage } from '../slices/appSlice';
import { data } from '../app/appData';
import SubHeader from '../components/SubHeader';

export default function Intro() {
  const dispatch = useDispatch();

  const handleImageClick = (src) => {
    dispatch(showImage(src));
  }

  return (
    <>
      <SubHeader/>
      <div
        className='w-full flex justify-center flex-wrap bg-gray-300'
      >
        <div
          className='w-full p-4 sm:w-[65vw] xl:w-[55vw] bg-gray-400'
        >
            <figure 
              className="float-left mr-4"
            >
              <img
                className="w-[20vw] cursor-pointer"
                src={data.photo.me}
                alt="Me"
                onClick={() => handleImageClick(data.photo.me)}
              />
            </figure>
            {data.text.intro.items.map((part, index) => (
              <p
                key={index}
                className="indent-5 pb-2 sm:text-base xl:text-lg"
              >
                {data.text.intro[part]}
              </p>
            ))}
        </div>
      </div>
    </>
  );
}
