import { useLoaderData } from 'react-router-dom';
import { getProduct } from '../app/apiFakeStoreRequests';

export async function loader({ params }) {
  const response = await getProduct(params.id);
  return response;
}

export default function ItemCard() {
  const productData = useLoaderData();
  const ratingWidth = {
    width: Math.round(productData.rating.rate / 5 * 100) + '%',
  }

  return (
    <div
      className="w-[90vw] pt-4 mx-auto sm:w-[80vw] md:w-[70vw] flex justify-between"
    >
      <div
        className="min-h-[80vh]"
      >
        <div
          className="flex justify-around flex-wrap"
        >
          <div
            className="max-h-[40vw] sm:max-h-[36vh]"
          >
            <div
              className="w-[30vw] h-[30vw] sm:w-[25vw] sm:h-[30vh] mb-2 p-2 border-2 border-gray-200 rounded-xl flex justify-center items-center"
            >
              <img
                src={productData.image}
                alt=""
                className="max-h-full max-w-full"
              />
            </div>
            <div
              className="w-[30vw] sm:w-[25vw] flex justify-center"
            >
              <div
                className="w-4/5 md:w-1/4 relative"
              >
                <img 
                  src="/img/stars.png"
                  alt=""
                  className="w-full z-10"
                />
                <div
                  className="absolute h-full top-0 left-0  bg-yellow-300 z-[-2]"
                  style={ratingWidth}
                >
                </div>
              </div>
            </div>
            <div
              className="w-[30vw] sm:w-[25vw] mt-1 text-center text-xs sm:text-base"
            >
              Рейтинг: &nbsp; {productData.rating.rate}
            </div>
          </div>
          <div
            className="w-[50vw] sm:w-[45vw] md:w-[40vw] max-h-[40vw] sm:max-h-[35vh]"
          >
            <div
              className="text-center"
            >
              <h1
                className="text-base md:text-xl mb-5 xl:text-2xl"
              >
                {productData.title}
              </h1>
            </div>
          </div>
        </div>
        <div
          className="w-full mx-auto my-6 p-4"
        >
          <div
            className="py-2"
          >
            <span>
              <strong>Описание: &nbsp; </strong> {productData.description}
            </span>
          </div>
          <div
            className="py-2"
          >
            <span>
              <strong>Цена: &nbsp; </strong> {(productData.price * 100).toString() + ' ₽'}
            </span>
          </div>
          <div
            className="text-xs"
          >
            <span>
              Осталось: &nbsp; {productData.rating.count} &nbsp; шт.
            </span>
          </div>
          <div
            className="w-full py-3 text-center"
          >
          <button
              className="w-[25vw] h-[10vw] sm:w-24 sm:h-8 text-xs border-2 border-gray-400 rounded-md hover:border-gray-500"
          >
            В корзину
          </button>
        </div>
        </div>
      </div>
    </div>
  );
}