import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  favorite: [],
  cart: [],
  view: 'list',
  storeData: [],
};

export const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    addFavorite: (state, action) => {
      return state.favorite.push(action.payload);
    },
    listView: (state) => {
      return { ...state, view: 'list' };
    },
    tileView: (state) => {
      return { ...state, view: 'tile' };
    },
    savedStoreData: (state, actions) => {
      return { ...state, storeData: actions.payload }
    },
    addRemoveToFavorite: (state, action) => {
      if (state.favorite.includes(action.payload)) {
       return { ...state, favorite: state.favorite.filter((el) => el !== action.payload) }
      }

      state.favorite.push(action.payload);
      return state;
    },
    loadFavorite: (state, action) => {
      return { ...state, favorite: action.payload };
    },
  },
});

export const {
  addFavorite,
  listView,
  tileView,
  savedStoreData,
  addRemoveToFavorite,
  loadFavorite,
} = storeSlice.actions;

export default storeSlice.reducer;
