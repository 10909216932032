import { useSelector } from 'react-redux';
import ItemListView from '../components/ItemListView';
import { useNavigate } from 'react-router-dom';

export default function Favorite() {
  const storeState = useSelector((state) => state.store);
  const navigate = useNavigate();
  
  return (
    <div
      className="w-full bg-slate-200 z-0"
    >
      <div
        className="w-full mx-auto lg:w-[70vw]"
      >
        <h1
          className="text-xl text-center"
        >
          Избранное:
        </h1>
        <div
          className="w-full p-3 min-h-[80vh]"
        >
          {storeState.favorite.length > 0 ? (
            storeState.storeData.map((el) => {
              if (storeState.favorite.includes(el.id)) {
                return (
                  <ItemListView
                    key={el.id}
                    data={el}
                  />
                ); 
              }
            })
          ) : (
            <div
              className="w-full h-[80vh] flex flex-col items-center"
            >
                <img
                  src="/img/broken-heart.png"
                  alt=""
                  className="w-[15%] mt-32"
                />
                <h2
                  className="text-lg my-5"
                >
                  Вы ничего не добавили в избранное 😔
                </h2>
                <button
                  className="w-32 h-7 text-xs border-2 border-gray-400 rounded-md hover:border-gray-500"
                  onClick={() => navigate(-1)}
                >
                  Вернуться
                </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
