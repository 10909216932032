import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className='w-full h-[60vh] flex flex-col justify-center items-center'>
      <h1>Ой, что-то пошло не так...</h1>
      <p>Пока не ясно что, но я попробую это исправить)))</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
