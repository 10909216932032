import { useDispatch } from 'react-redux';
import { closeContacts } from '../slices/appSlice';

export default function Contacts() {
  const dispatch = useDispatch();

  // Меняем состояние для удаления компонента с контактами
  const handleClickClose = () => {
    dispatch(closeContacts());
  }

  return (
    <div 
      className="fixed w-full h-full left-0 top-0 bg-black bg-opacity-70 flex justify-center items-center z-10 overflow-x-hidden"
    >
      <div 
        className="w-[300px] h-[300px] p-4 relative rounded-md bg-white box-border z-20 flex flex-col flex-wrap items-center text-center"
      >
        <div 
          className="absolute text-white text-5xl top-[-40px] right-[-30px] z-20 cursor-pointer hover:text-slate-300"
          onClick={handleClickClose}
        >
          &times;
        </div>
        <a href="tel:+79213731438">+7(921)373-14-38</a>
        <a href="https://t.me/alexdrum85" target="_blank"rel="noreferrer">
          <img 
            src={"/img/telegram.svg"} 
            alt="T" 
            className="w-12 h-12 my-3"
          />
        </a>
        <a href="https://wa.me/79213731438?text=Привет." target="_blank" rel="noreferrer">
          <img 
            src={"/img/whatsapp.svg"}
            alt="WA"
            className="w-12 h-12 my-3"
          />
        </a>
        <a href="https://vk.com/id1224452" target="_blank" rel="noreferrer">
          <img
            src={"/img/vk.svg"}
            alt="VK"
            className="w-12 h-12 my-3"
          />
        </a>
        <a href="mailto:alexdrum@mail.ru">alexdrum@mail.ru</a>
      </div>
    </div>
  );
}
