import { Form, Link, redirect } from 'react-router-dom';
import { print } from '../app/apiFakeStoreRequests';
import { useSelector } from 'react-redux';

export async function action({ request }) {
  console.log('action');
  const formData = await request.formData();
  const data = Object.fromEntries(formData);
  await print(data);
  return redirect("./searchresult");
}

export default function StoreHeader() {
  const storeState = useSelector((state) => state.store);

  return (
    <header
      className="w-screen h-[8vh] flex items-center justify-around bg-gray-200"
    >
      <div
        className="w-[20vw] md:[12vh] ml-[1vw] md:ml-[5vw]"
      >
        <Link
          to={"/fakestore"}
          className="flex"
        >
          <figure
              className='w-[7vh] flex'
            >
              <img 
                src="/img/u-fotor.png"
                alt="STORE"
                className="cursor-pointer"
              />
          </figure>
          <span
            className="text-gray-600 leading-[50px]"
          >
            store
          </span>
        </Link>
      </div>
      <Form
        method='post'
        className="w-[50vw] h-full flex items-center justify-center"
      >
        <input
          name="search"
          type="search"
          className="w-[90%] md:w-3/4 h-[4vh] px-2 border-2 border-gray-400 rounded-lg outline-none"
        />
        <button
          className="hidden sm:block ml-4 w-[4vh] h-[4vh] p-1 border-2 border-gray-400 rounded-lg active:shadow-[0_0_10px_-4px_#ffffff_inset] bg-white hover:border-gray-500"
        >
          <img src="/img/search.png"  alt="" />
        </button>
      </Form>
      <div
        className='w-[25vh] h-full flex items-center sm:justify-around'
      >
        <Link
          to={"./favorite"}
        >
          <div
            className="w-[4vh] h-[4vh] ml-3 p-1 border-2 border-gray-400 rounded-lg active:shadow-[0_0_10px_-4px_#ffffff_inset] bg-white cursor-pointer hover:border-gray-500"
          >
            <img
              src={storeState.favorite.length > 0 ? "/img/add_to_favorite.png" : "/img/favorite.png"}
              alt=""
            />
          </div>
        </Link>
        <div
          className="flex items-center"
        >
          <Link
            to={"./cart"}
          >
            <div
              className="w-[4vh] h-[4vh] ml-3 p-1 border-2 border-gray-400 rounded-lg active:shadow-[0_0_10px_-4px_#ffffff_inset] bg-white cursor-pointer hover:border-gray-500"
            >
              <img src="/img/cart.png" alt="" />
            </div>
          </Link>
          <div
            className="ml-2 min-w-[3vh] py-1 rounded-md bg-white text-center text-xs"
          >
            0р
          </div>
        </div>
      </div>
    </header>
  );
}
