import { getProducts } from '../app/apiFakeStoreRequests';
import { Link, Outlet } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';
import StoreHeader from '../components/StoreHeader';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { savedStoreData } from '../slices/storeSlice';

export default function FakeStore() {
  const dispatch = useDispatch();

  useEffect(()=>{
    getProducts()
    .then((data) => dispatch(savedStoreData(data)))
  },[]);

  return (
    <>
      <section className="w-screen mt-[34px] bg-red-300 text-center">
        <p>
          Магазин создан в качестве наглядного примера!
          <b> Не является публичной офертой! </b> 
          Данные сгенерированы с помощью сервиса&nbsp;
          <Link 
            to="https://fakestoreapi.com/"
            className="underline"
            target="_blank"
          >
            Fake Store API
          </Link>
        </p>
      </section>
      <StoreHeader />
      <LoadingSpinner></LoadingSpinner>
      <section
        className='overflow-hidden'
      >
        <div
          className="w-ful lg:h-[7vh] text-center"
        >
          <p
            className="text-2xl lg:text-4xl"
          >
            Fake Store еще в процессе создания...
          </p>
          <p
            className="text-xs"
          >
            (занимаюсь написанием дипломной работы)
          </p>
        </div>

        <Outlet />
      </section>
    </>
  );
}
