import { RotatingLines } from 'react-loader-spinner';

export default function LoadingSpinner() {
  return (
    <div
      className="fixed left-[50%] top-[50%] transform translate-y-[-50%] translate-x-[-50%] bg-white bg-opacity-0 flex justify-center z-0 overflow-x-hidden"
    >
      <div
        className="w-28 h-28 flex justify-center items-center"
      >
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="60"
          visible={true}
        />
      </div>
    </div>
  );
}
