import { Link } from 'react-router-dom'
export const data = {
  photo: {me: './img/me.jpeg', murmashi: './img/murmashi_on_map.png'},
  languages: ['Русский', 'English', 'Français', 'Polski', 'Deutsch'],
  menus: {
    submenu: {'/':'вступление', '/aboutme': 'о себе', '/resume': 'резюме'},
  },
  certificatesPaths: [
    './certificates/certificate_python.jpg',
    './certificates/certificate_pgsql.jpg',
    './certificates/certificate_python-profwork.jpg',
    './certificates/certificate_git.jpg',
    './certificates/certificate_django.jpg',
    './certificates/certificate_html-css.jpg',
    './certificates/certificate_js.jpg',
    './certificates/certificate_deep0_js.jpg',
    './certificates/certificate deep_js.jpg',
    './certificates/certificate_react.jpg'
  ],
  text: {
    wellcome: [],
    intro: {
      items: ['part1', 'part2', 'part3', 'part4', 'part5'],
      part1: `Всем привет! Как вы, наверное, уже поняли, меня зовут Алексей) 
        Добро пожаловать на мой тестовый сайт. Это мой перый проект, и я не решил еще, для чего буду использовать его в будущем. Ну а пока будет работать в 
        качестве pet-проекта, саморекламы, резюме и сопроводительного письма для ускорения 
        процесса трудоустройства).`,
      part2: `Т.к. я начинающий разработчик, а не UX/UI-диз или коприрайтер, думаю, мне будут простительны ошибки расстановки
        элементов по фэншую, отсутствие подключения заковыристых шрифтов(пойдем по классике) и складности текта. Все беру с головы, ну и из некоторых ДЗ. 
        Касательно цветов - поймут знающие меня люди) Вообще я 
        постараюсь на этой площадке реализовать некий полигон, и по максимуму использовать все 
        полученные при прохождении курса знания. Для начала буду реализовывать только frontEnd, позже реализую простенький backEnd(next или koa). Пока нужно доделать изначальную задумку).`,
      part3: <>Сайт (version_2.0)) сделал на React, с подключением react-router, react-redux(toolkit), saga. Часть компонентов установил пакетами, стили реализовал через Tailwind.&nbsp;
        <Link to="http://jungsite.ru/" target="_blank" className='underline'>Предыдущая версия</Link> на классическом HTML и CSS с подключением JS без пагинации и сборки. 
        Подключил Яндекс.Метрику для отслеживания посещений и просмотра действий в Вебвизоре. Баловство) но все же интересно!
        Пока оставлю как есть. Крутится идея какую-нибудь игру прикрутить простенькую: мной реализована игра в слова 
        (5 букв существительное с 5 попыток) на python,  идумаю, не составит труда ее на JS перевести с красивым оформлением. В общем буду 
        допиливать все это дело по-тихоньку.</>,
      part4: `Конечно с учетом того, что последний год, в соответствии с программой обучения, 
        я изучаю JS, немного подзабылись практические навыки python и инструменты работы с 
        базами данных(postgreSQL), но повторить пройденный материал не сложно, конечно, если будет необходимость.`,
      part5: `Буду рад обратной связи на мои отклики от потенциальных работодателей, т.к. сухие и шаблонные отписки, если честно, 
        слегка угнетают, а так же не отвечают на главный вопрос:"Что не так?", сопроводительное ли скучное, резюме плохо оформлено, 
        маловато знаний и хардов для вашей должности, ну или, как говориться, "рожей не вышел") В общем: буду признателен за конкретику.`
    },
    aboutMe:{
      items: ['part1', 'part2', 'part3'],
      part1: `Родом я из п.Мурмаши, который находится не далеко от Мурманска. В школе всегда был хорошистом.
        С девятого класса, а именно тогда родители купили нам с братом(у меня есть старший брат) комп, увлекся "железом". На тот 
        момент это был 400й селерон на 98й винде) Научился переустанавливть операционку(помнится через какой-то commander), 
        устанавливать игры(это же сама цель была))) и всякие проги, для коректного 
        запуска игр. Но увлечение осталось увлечением, а учиться я пошел на инженера электрика, по "стопам отца")`,
      part2: `Отучился не без проблем: в конце 4-ого курса устроился работать в сети("Ленэнерго"), и совмещение на 5м курсе 
        с первой попытки не удалось и пришлось брать академический отпуск. Естесственно учебу я не забросил, 
        успешно защитил диплом(до сих пор защита снится))) В последствии работал в различных организациях(см. резюме), 
        достигая определенных вершин, укрепляя и пополняя знания в электроэнергетике. В определенный момент я осознал, что 
        достиг определенного потолка в знаниях и карьере, решил пойти в сферу ЖКХ, но быстро понял, что это не мое.`,
      part3: <>
        Пришел к выводу, что нужно менять профиль трудовой деятельности. Была мысль еще в кулинарию пойти))) но перевесило направление 
        разработки. На тот момент у меня был небольшой опыт после прохождения  курсов основ python3 от
        {<Link to="https://devision.io" target="_blank" className='underline'> Devision</Link>} подразделение
        {<Link to="https://realweb.ru" target="_blank" className='underline'> ИА Риалвеб</Link>} летом 2021г., поэтому 
        выбрал направление "fullstack-разработчик на Python" на обучающей платформе "Нетология". Вообще рассматривал две площадки, 
        вторая - Яндекс Практикум, но в пользу первой сыграла цена вопроса.
      </>,
    },
    resume: {
      items: ['part1', 'part2', 'part3', 'part4', 'part5', 'part6'],
      part1: `Скорее всего вы уже ознакомились с моим резюме, но на всякий случай оставлю ссылки. Сюда его целиком вставлять не вижу смысла. 
        А вот свидетельства, пожалуй, имеет смысл разместить.`,
      part2: <>
        <a href="./pdf/resume.pdf" download="Резюме_Алексей_Юнг"> 
          Ссылка на скачивание: &nbsp;
          <img src="./img/downloads.svg" alt="download" className='inline'/>
        </a>
      </>,
      part3: <>
        Ссылка&nbsp;
        <Link to="https://hh.ru/resume/3b37d158ff04a9734f0039ed1f49794d536c71" className='underline' target="_blank"> 
          hh.ru
        </Link>
        &nbsp;(слишком много правил для публикации лого)))
      </>,
      part4: `В начале пути готов работать "за хлеб и воду"(ну или чай с печенюшкой), т.е. не оплачиваемая стажировка приемлема, 
        но в зависимости от сроков. Лучше в офисе с "живым" подключением ментора, но это не принципиально). Просто 
        считаю такой формат более годным для продуктивной коммуникации. При необходимости готов к переработкам.`,
      part5: `Я адекватно оцениваю свои способности на данный момент, поэтому нет никаких завышенных 
        ожиданий относительно ЗП на ближайшее время.`,
      part6: `При успешном окончании каждого модуля площадка выдавала электронное свидетельство. 
        После написания дипломной работы, на сколько помню, должны выдадать общее свидетельство.
        Закину пока сюда, а позже сделаю отдельное подменю:`,
    },
  }
}