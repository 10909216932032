import { useDispatch, useSelector } from 'react-redux';
import { showImage } from '../slices/appSlice';
import { addRemoveToFavorite } from '../slices/storeSlice';
import { Link } from 'react-router-dom';

export default function ItemListView({ data }) {
  const storeState = useSelector((state) => state.store);
  const dispatch = useDispatch();

  const handleImageClick = () => {
    dispatch(showImage(data.image));
  };

  const handleFavoriteClick = () => {
    dispatch(addRemoveToFavorite(data.id));
  }
  
  return (
    <div
      className="w-full my-2 h-[14vh] mb-3 flex justify-around items-center rounded-xl bg-white shadow-[0_0_8px_0_rgba(34,60,80,0.3)]"
    >
      <div
        className="w-1/5 flex justify-center cursor-pointer"
        onClick={handleImageClick}
      >
        <img 
          src={data.image}
          alt="item"
          className="max-h-[10vh] max-w-[70%]"
        />
      </div>
      <Link
       to={`/fakestore/item_card/${data.id}`}
       className="w-2/5 h-full flex items-center"
      >
        <div
          className="w-full text-sm md:text-base"
        >
          <p>
          {data.title.length > 50 ? data.title.slice(0, 50) + ' ...' : data.title}
          </p>
        </div>
      </Link>
      <div
        className="w-[15%] font-bold"
      >
        <p
          className="text-sm md:text-base"
        >
          {(data.price * 100).toString() + ' ₽'}
        </p>
      </div>
      <div
        className="w-[20%] h-full flex flex-col justify-around items-center"
      >
        <div
          className="w-[3vh] h-[3vh] cursor-pointer"
          onClick={handleFavoriteClick}
        >
          <img
            src={storeState.favorite.includes(data.id) ? "/img/add_to_favorite.png" : "/img/favorite.png"}
            alt=""
            className="h-full"
          />
        </div>
        <div
          className="w-[90%] h-[3vh] "
        >
          <button
            className="w-full h-full text-xs border-2 border-gray-400 rounded-md hover:border-gray-500"
          >
            В корзину
          </button>
        </div>
      </div>
    </div>
  );
}
