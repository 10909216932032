import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showContacts} from '../slices/appSlice';

export default function MainHeader() {
  const dispatch = useDispatch();

  // Меняем состояние для отображения компонента с контактами
  const handleContacts = () => {
    dispatch(showContacts());
  };
  
  return (
    <header
      className="w-screen overflow-x-hidden z-[2]" 
    >
      <div
        className="w-full h-9 py-1 fixed top-0 bg-[#555] font-sans text-sm font-bold md:text-base xl:text-lg text-white"
      >
        <nav
          className="w-full h-full flex justify-around items-center"
        >
            <button
            className="bg-[#22262c] active:shadow-[0_0_10px_-4px_#ffffff_inset] box-border border border-[#959595] hover:border-[#bfbfbf]  rounded-md"
          >
            <Link
              to={'/'}
              className="inline-block w-full h-full px-4"
            >
              Главная
            </Link>
          </button>
          <button
            className="bg-[#22262c] active:shadow-[0_0_10px_-4px_#ffffff_inset] box-border border border-[#959595] hover:border-[#bfbfbf]  rounded-md"
          >
            <Link
              to={'/fakestore'}
              className="inline-block w-full h-full px-4"
            >
              Магазин(fake)
            </Link>
          </button>
          <button
            className="px-4 bg-[#22262c] active:shadow-[0_0_10px_-4px_#ffffff_inset] box-border border border-[#959595] hover:border-[#bfbfbf]  rounded-md"
            onClick={handleContacts}
          >
            Контакты
          </button>
        </nav>
      </div>
    </header>
  );
}
