export async function print(data) {
  console.log(data);
}

export async function getProducts() {
  try {
    const response = await fetch(process.env.REACT_APP_STORE_URL);
    const data = response.json();
    return data;
  } catch(err) {
    throw new Error(err)
  }
}

export async function getProduct(params) {
  try {
    const response = await fetch(process.env.REACT_APP_STORE_URL+params);
    const data = response.json();
    return data;
  } catch(err) {
    throw new Error(err)
  }
}
