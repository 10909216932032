import { Link } from 'react-router-dom';

export default function SiteInfo() {
  return (
    <div
        className="w-full flex justify-center flex-wrap bg-gray-300"
      >
        <div
          className="w-full mt-8 p-5 md:w-[65vw] xl:w-[55vw] bg-gray-400"
        >
          <p
            className="whitespace-pre-wrap indent-5 pb-2 sm:text-base xl:text-lg"
          >
            {`"dependencies": {
              "@reduxjs/toolkit": "^2.0.1",
              "@szhsin/react-menu": "^4.1.0",
              "@testing-library/jest-dom": "^5.17.0",
              "@testing-library/react": "^13.4.0",
              "@testing-library/user-event": "^13.5.0",
              "localforage": "^1.10.0",
              "react": "^18.2.0",
              "react-dom": "^18.2.0",
              "react-loader-spinner": "^6.1.0",
              "react-redux": "^9.0.2",
              "react-router-dom": "^6.20.1",
              "react-scripts": "5.0.1",
              "redux": "^5.0.0",
              "replicate": "^0.24.0",
              "tailwindcss": "^3.3.6",
              "web-vitals": "^2.1.4"
            }`}
          </p>
          <p
            className="indent-5 pb-2 sm:text-base xl:text-lg"
          >
            Данные для Fake store сгенерированы с помощью сервиса Fake Store API: &nbsp;
            <Link 
              to="https://fakestoreapi.com/"
              className="underline"
              target="_blank"
            >
              https://fakestoreapi.com/
            </Link> 
          </p>
          <div
            className="h-[33vh]"
          >
            <p
              className="indent-5 pb-2 sm:text-base xl:text-lg"
            >
              Большинство используемых в проекте изображений/иконкок/значков найдены на бесплатном ресурсе &nbsp;
              <Link
                to="https://icon-icons.com/"
                className="underline"
                target="_blank"
              >
                https://icon-icons.com/
              </Link>
              &nbsp;, некоторые сгенерированы или изменены с помощью AI Playground &nbsp;
              <Link
                to="https://playgroundai.com/"
                className="underline"
                target="_blank"
              >
                https://playgroundai.com/
              </Link>
            </p>
          </div>
        </div>
      </div>
  );
}
