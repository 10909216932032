import SubHeader from '../components/SubHeader';
import { data } from '../app/appData';
import { useDispatch } from 'react-redux';
import { showImage } from '../slices/appSlice';

export default function AboutMe() {
  const dispatch = useDispatch();

  const handleImageClick = (src) => {
    dispatch(showImage(src));
  }
  
  return (
    <>
      <SubHeader/>
      <div
        className="w-full flex justify-center flex-wrap bg-gray-300"
      >
        <div
          className="w-full p-4 sm:w-[65vw] xl:w-[55vw] bg-gray-400"
        >
          <figure 
            className="float-left mr-4 cursor-pointer"
          >
            <img
              className="w-[30vh]"
              src={data.photo.murmashi}
              alt=""
              onClick={() => handleImageClick(data.photo.murmashi)}
            />
          </figure>
          {data.text.aboutMe.items.map((part, index) => (
            <p
              key={index}
              className="indent-5 pb-2 sm:text-base xl:text-lg"
            >
              {data.text.aboutMe[part]}
            </p>
          ))}
        </div>
      </div>
    </>
  );
}
