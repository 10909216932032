import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  error: null,
  loading: false,
  showContacts: false,
  showImage: false,
  language: 'russian',
  subMenuState: {submenu0: 'underline', submenu1: '', submenu2: ''},
  sizeOnHeight: null,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    loading: (state) => {
      return {...state, loading: true};
    },
    loadingOf: (state) => {
      return {...state, loading: false};
    },
    showContacts: (state) => {
      return {...state, showContacts: true };
    },
    closeContacts: (state) => {
      return {...state, showContacts: false};
    },
    showImage: (state, actions) => {
      return {...state, showImage: actions.payload}
    },
    closeImage: (state) => {
      return {...state, showImage: false}
    },
    savedStoreData: (state, actions) => {
      return {...state, storeData: actions.payload}
    },
    setOnHeight: (state, action) => {
      return {...state, sizeOnHeight: action.payload };
    },
  },
});

export const { showContacts, closeContacts, loading, showImage, closeImage, setOnHeight } = appSlice.actions;
export default appSlice.reducer;
